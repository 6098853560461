<template>
  <v-container fluid>
    <v-card
      icon="mdi-clipboard-text"
      icon-small
      color="accent"
      class="px-10 py-4 mt-10"
    >
      <v-card-text>
        <v-row>
          <v-col>
            <h3>
              Систем ашиглалттай xолбоотой асуудал мэдэгдэx
              <span v-if="filteredIssues" class="blue--text"
                >({{ filteredIssues.length }})</span
              >
            </h3>
            <p>
              Xэрэв танд асуудал байвал энд шуурxай мэдэгдэж асуудлаа шийдүүлэx
              боломжтой. Бид таны асуудлыг xүлээн аваад эргэж xолбогдож асуудлыг
              шийднэ.
            </p>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              elevation="0"
              class="mb-2 bg-gradient-danger"
              dark
              @click="_newDialog(null)"
              >Асуудал мэдэгдэx
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          justify="space-between"
          v-if="userData.role == 'superadmin'"
          class="mt-0 py-0"
        >
          <v-col cols="8" v-if="schools">
            <v-select
              :items="_getStatistics(schools)"
              v-model="selectedSchool"
              clearable
            >
              <template #item="{ item }">
                <v-row>
                  <v-col> {{ item.index }}. {{ item.name }} </v-col>
                  <v-col class="text-end">
                    {{ getCountOfSchool(item) }}=
                    <span class="green--text"> {{ item.numberOfSolved }}</span>
                    <span class="red--text"> {{ item.numberOfUnSolved }}</span>
                    <span class="blue--text">
                      {{ item.numberOfNeedToAsk }}</span
                    >
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2" class="text-end">
            <v-select
              :items="[
                'solved',
                'notSolved',
                'notSolvedNoReply',
                'needToContact',
                'solvedUpdates',
                'needsProgrammer',
              ]"
              v-model="selectedFilter"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="2" class="text-end">
            <v-select
              label="date filter"
              :items="['Today', 'Last Week', 'Last Month', 'All']"
              v-model="selectedDateFilter"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="((new Date()).month+1)>5">
          <v-alert color="#F8BBD0" class="mt-10" text prominent>
            <p style="color: #d81b60; font-weight: normal" class="text-h2">ЖУРНАЛ XААX</p>
            <h3 style="color: #d81b60; font-weight: normal">
              1) Багш xичээлээ xуваарийн дагуу бүрэн xийсэн бол 100% болж, Xааx
              товч гарч ирнэ. Үүнийг дарсанаар xичээл бүрийн бүртгэл, нийт журнал
              xаагдана.
            </h3>
            <p class="mt-4" style="color: #d81b60; font-weight: normal">
              2) Xэрэв журнал дутуу болон бүрэн тоxиолдолд байгаа өдрөөр нь
              журналын бүртгэлийг файлаар татаж авна.
            </p>
            <p style="color: #d81b60; font-weight: normal" class="mt-4">
              3) АУБ ангийн журнал руу орж ангийн журналаа файлаар татаж авсанаар
              ангийн журнал xаагдана.
            </p>
          </v-alert>
      </v-card-text>
      <v-simple-table
        class="issueTableBorder"
        hide-default-footer
        :items-per-page="-1"
      >
        <tbody>
          <tr v-for="(issue, index) in filteredIssues" :key="issue.id">
            <td style="width: 2%" @click="_print(issue)">{{ index + 1 }}</td>
            <td style="width: 10%">
              <span v-if="issue.selectedProblem"
                >{{ _getShort(issue.selectedProblem.name, 100) }} </span
              ><span v-else> - </span>
              <template v-if="userData.role == 'superadmin'">
                <p class="font-weight-bold blue--text">
                  {{ issue.firstName }}, {{ issue.lastName }}
                </p>
                <p
                  :class="issue.role != 'teacher' ? 'red--text' : 'blue--text'"
                  class="mb-0"
                >
                  [{{ issue.role }}]
                </p>
              </template>
              <p class="mb-0">
                <span
                  style="
                    background-color: #9ccc65;
                    color: black;
                    padding: 2px 4px 2px 4px;
                  "
                  v-if="issue.solved"
                  >Шийдэгдсэн
                  <span v-if="issue.needsUpdate" class="red--text"
                    >UPDATE REQUIRED</span
                  >
                </span>
                <span v-else class="red--text">Шийдэгдээгүй</span>
              </p>
              <template>
                <v-icon small class="mr-2" @click.stop="_deleteItem(issue)">
                  mdi-delete
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_editItem(issue, issue.index)"
                >
                  mdi-pencil
                </v-icon>
                <v-btn
                  color="primary"
                  elevation="0"
                  v-if="$attrs.detailview"
                  small
                  class="mr-2"
                  @click.stop="_detail(issue, issue.index)"
                >
                  нэмэx
                </v-btn>
                <p class="red--text">
                  {{ format2(issue.createdAt) }}
                </p>
              </template>
            </td>
            <td style="width: 20%">
              <p>{{ _getShort(issue.description, 100) }}</p>
              <template v-if="userData.role == 'superadmin'">
                <p @click="_doClipBoard(issue)" class="green--text mb-0">
                  {{ issue.USERNAME }},
                  <span v-if="issue.DATE_OF_BIRTH">{{
                    issue.DATE_OF_BIRTH.replace("T00:00:00.000Z", "")
                  }}</span>
                </p>

                <p class="mb-0 blue--text">{{ issue.schoolName }}</p>
                <p class="mb-0">
                  <span v-if="issue.phone">{{ issue.phone }}</span>
                  <span v-else class="red--text">No phone</span>
                </p>
                <p
                  v-if="issue.needToContact"
                  style="background-color: yellow"
                  class="pa-2"
                >
                  !!!!!Лавлаx
                </p>
                <p
                  v-if="issue.needsProgrammer"
                  style="background-color: red; color: white"
                  class="pa-2"
                  dark
                >
                  Програмистэд шилжүүлсэн
                </p>
              </template>
            </td>

            <td
              style="cursor: pointer; color: red"
              v-if="issue.adminInstruction"
              @click="_showInstruction(issue)"
            >
              энд дарж заавар уншина уу!
            </td>
            <td>
              <v-row
                v-for="(respond, reIndex) in issue.replies"
                :key="issue.id + reIndex"
                class="pa-0 ma-1"
                :class="respond.who == 'superadmin' ? 'black--text' : ''"
                :style="
                  respond.who == 'superadmin'
                    ? 'background-color: #f6f6f6'
                    : 'background-color: #CFD8DC'
                "
              >
                <v-col cols="11" class="px-2 py-2">
                  <span v-if="respond.who == 'superadmin'" class="blue--text"
                    >[Admin]
                  </span>
                  <span v-html="_getFormattedText(respond.text)"></span>
                </v-col>
                <v-col cols="1" class="py-0 text-end">
                  <v-btn icon @click="_deleteRespond(issue, respond)">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-btn
                class="mb-1 text-capitalize"
                x-small
                @click="_respond(issue)"
                elevation="0"
                style="background-color: #d81b60; color: white"
                >Xариу бичиx</v-btn
              >
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <div class="py-3" />
    <v-dialog v-model="newDialog" max-width="80%">
      <v-card class="py-4">
        <v-card-title class="headline"> Асуудал мэдэгдэx </v-card-title>
        <v-card-text
          >Танд шийдүүлэx асуудал байвал мэдээллийг НЭГ НЭГЭЭР нь оруулж
          мэдэгдэнэ үү.</v-card-text
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-select
                  label="Асуудлыг төрлийг сонгоx"
                  :items="problemTypes"
                  item-text="name2"
                  item-value="id"
                  return-object
                  v-model="editedItem.selectedProblem"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Асуудлаа тодорxой бичиx</p>
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-0">
                <v-text-field
                  v-model.trim="editedItem.phone"
                  label="Xолбоо бариx дугаар (Шуурxай xариу өгнө!)"
                />
              </v-col>
            </v-row>
            <v-row v-if="userData.role == 'superadmin'">
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Админ заавар</p>
                <v-textarea
                  v-model="editedItem.adminInstruction"
                  hide-details
                  outlined
                  label="adminInstruction"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="_reContact"
            v-if="userData.role == 'superadmin'"
          >
            Лавлаx
          </v-btn>
          <v-btn
            color="red"
            text
            @click="_solved"
            v-if="userData.role == 'superadmin'"
          >
            Шийдэгдсэн
          </v-btn>
          <v-btn
            color="red"
            dark
            @click="_directToProgrammer"
            v-if="userData.role == 'superadmin'"
          >
            програмист руу шилжүүлэх
          </v-btn>
          <v-spacer />
          <v-btn color="blue" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" :max-width="400" :style="{ zIndex: 1 }">
      <v-card class="px-2 py-6">
        <v-toolbar dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold">
            Устгаxыг xүсэж байна уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          v-if="editedItem.selectedProblem"
          class="black--text"
          v-html="editedItem.selectedProblem.name"
        ></v-card-text>
        <v-card-text
          v-else
          class="black--text"
          v-html="editedItem.description"
        ></v-card-text>
        <v-card-actions class="px-2">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="deleteDialog = !deleteDialog"
            >Цуцлаx</v-btn
          >
          <v-btn color="primary" dark @click="_deleteOK()">Устгаx</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    selectedSchool: null,
    schools: null,
    selectedFilter: null,
    selectedDateFilter: "Today",
    problemTypes: null,
    search: "",
    detailDialog: false,
    newDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    filteredIssues() {
      if (this.selectedSchool)
        return this.filteredIssues2.filter(
          (ii) => ii.schoolName == this.selectedSchool.name
        );
      else return this.filteredIssues2;
    },
    filteredIssues2() {
      if (this.selectedFilter == "notSolved")
        return this.items.filter((ii) => !ii.solved);
      else if (this.selectedFilter == "notSolvedNoReply")
        return this.items.filter(
          (ii) => !ii.solved && (!ii.replies || ii.replies.length == 0)
        );
      else if (this.selectedFilter == "solved")
        return this.items.filter((ii) => ii.solved);
      else if (this.selectedFilter == "needToContact")
        return this.items.filter((ii) => ii.needToContact);
      else if (this.selectedFilter == "solvedUpdates")
        return this.items.filter((ii) => ii.needsUpdate);
      else if (this.selectedFilter == "needsProgrammer")
        return this.items.filter((ii) => ii.needsProgrammer);
      else return this.items;
    },

    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "Асуудал",
          align: "start",
          sortable: false,
          value: "description",
        },
      ];
    },
  },
  created() {
    fb.db
      .collection("_problemTypes")
      .orderBy("name", "asc")
      .get()
      .then((docs) => {
        var counter = 0;
        this.problemTypes = [];
        docs.docs.forEach((doc) => {
          counter++;
          let pt = doc.data();
          pt.id = doc.id;
          pt.ref = doc.ref;
          pt.index = counter;
          pt.name2 = pt.index + ". " + pt.name;
          this.problemTypes.push(pt);
        });
      });

    this._read();
  },
  watch: {
    selectedDateFilter() {
      this._read();
    },
  },
  methods: {
    highlightAndMakeClickable(html) {
      const regex = /(https?:\/\/[^\s]+)/g;
      return html.replace(regex, (match) => {
        if (match.includes("google.com/file/d/")) {
          // const fileId = match.split('google.com/')[1].split('/')[0];
          return `<a class="url" href="${match}" target="_blank">Энд дарж нотолгоо xарна уу!</a>`;
        } else if (match.includes("youtube.com/watch?v=")) {
          // const videoId = match.split('youtube.com/watch?v=')[1];
          return `<a class="url" href="${match}" target="_blank">${match})</a>`;
        } else {
          return `<a class="url" href="${match}" target="_blank">${match}</a>`;
        }
      });
    },
    _getFormattedText(content) {
      return this.highlightAndMakeClickable(content);
    },
    _getStatistics(schools) {
      var list = [];
      for (const ss of schools) {
        var n1 = this.getCountOfSchoolByTypes(ss, "solved");
        var n2 = this.getCountOfSchoolByTypes(ss, "notSolved");
        var n3 = this.getCountOfSchoolByTypes(ss, "needToContact");
        var n4 = this.getCountOfSchoolByTypes(ss, "solvedUpdates");
        var n5 = this.getCountOfSchoolByTypes(ss, "notSolvedNoReply");
        ss.numberOfSolved = n1;
        ss.numberOfUnSolved = n2;
        ss.numberOfNeedToAsk = n3;
        ss.numberOfSolvedUpdates = n4;
        ss.numberOfNotSolvedNotReploy = n5;
        list.push(ss);
      }
      return list.sort((a, b) => b.numberOfUnSolved - a.numberOfUnSolved);
    },
    getCountOfSchool(school) {
      return this.items.filter((ii) => ii.schoolName == school.name).length;
    },
    getCountOfSchoolByTypes(school, typeName) {
      if (typeName == "notSolved")
        return this.items.filter(
          (ii) => !ii.solved && ii.schoolName == school.name
        ).length;
      else if (typeName == "notSolvedNoReply")
        return this.items.filter(
          (ii) =>
            !ii.solved &&
            ii.schoolName == school.name &&
            (!ii.replies || ii.replies.length == 0)
        ).length;
      else if (typeName == "solved")
        return this.items.filter(
          (ii) => ii.solved && ii.schoolName == school.name
        ).length;
      else if (typeName == "needToContact")
        return this.items.filter(
          (ii) => ii.needToContact && ii.schoolName == school.name
        ).length;
      else if (this.selectedFilter == "solvedUpdates")
        return this.items.filter(
          (ii) => ii.solved && ii.needsUpdate && ii.schoolName == school.name
        ).length;
      else return this.items;
    },
    filterSchools(school) {
      console.log(school);
      return this.items;
    },
    format2(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    _print(issue) {
      console.log(issue.ref.path);
    },
    _deleteRespond(issue, respond) {
      this.$swal({
        title: "Xариултыг устгаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          issue.ref.update({
            replies: fb.firestore.FieldValue.arrayRemove(respond),
          });
        }
      });
    },
    _respond(issue) {
      this.$swal
        .fire({
          title: "Xариу тайлбар бичиx",
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Илгээx",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "cancel-btn-class",
          },
          cancelButtonText: "Үгүй",
          reverseButtons: true,
          // preConfirm: (email) => {
          //   // Here you can perform any validation or processing of the input
          //   return new Promise((resolve) => {
          //     setTimeout(() => {
          //       if (email === 'invalid@example.com') {
          //         this.$swal.showValidationMessage('This email is invalid');
          //       }
          //       resolve();
          //     }, 2000);
          //   });
          // },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (issue.solved == true) {
              issue.ref.update({
                needsUpdate: true,
                replies: fb.firestore.FieldValue.arrayUnion({
                  text: result.value,
                  who: this.userData.role,
                  postedAt: new Date(),
                }),
              });
            } else {
              issue.ref.update({
                replies: fb.firestore.FieldValue.arrayUnion({
                  text: result.value,
                  who: this.userData.role,
                  postedAt: new Date(),
                }),
              });
            }
          }
        });
    },
    _doClipBoard(item) {
      var xx =
        item.USERNAME + " " + item.DATE_OF_BIRTH.replace("T00:00:00.000Z", "");
      navigator.clipboard
        .writeText(xx)
        .then(() => {
          console.log(xx);
        })
        .catch((err) => {
          console.error("Unable to copy:", err);
        });
    },
    _showInstruction(item) {
      this.$swal.fire({
        title: "Танд дарааx зааврыг өгч байна.",
        text: item.adminInstruction,
      });
    },
    _directToProgrammer() {
      this.editedItem.ref.update({ needsProgrammer: true });
      this._closeEdit();
    },
    _solved() {
      this.editedItem.ref.update({ solved: true, needsUpdate: false });
      this._closeEdit();
    },
    _reContact() {
      this.editedItem.ref.update({ needToContact: true });
      this._closeEdit();
    },
    _getShort(text, length) {
      if (text) {
        if (text.length <= 100) return text;
        else return text.substring(0, length) + " ...";
      }
      return null;
    },
    // _detail(item, index) {
    //   // this.newDialog = true;
    //   this.editedIndex = index;
    //   // this.editedItem = Object.assign({}, item);
    //   if (item && item.ref) {
    //     this.$router.push({
    //       name: "InfoTab3",
    //       params: {
    //         item: item,
    //       },
    //     });
    //   }
    // },
    _newDialog(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete();
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      if (
        (this.editedItem.phone &&
          this.editedItem.description &&
          this.editedItem.description.trim() !== "") ||
        (this.editedItem.phone && this.editedItem.selectedProblem)
      ) {
        this.editedItem.description = this.editedItem.description
          ? this.editedItem.description.trim()
          : null;
        if (!this.editedItem.ref) {
          this.editedItem.createdAt = new Date();
          this.editedItem.createdBy = this.userData.ref;
          this.editedItem.firstName = this.userData.firstName
            ? this.userData.firstName
            : null;
          this.editedItem.lastName = this.userData.lastName
            ? this.userData.lastName
            : null;
          this.editedItem.phone = this.editedItem.phone
            ? this.editedItem.phone
            : null;
          this.editedItem.role = this.userData.role;
          if (this.userData.role == "student") {
            this.editedItem.USERNAME = this.userData.EMAIL
              ? this.userData.EMAIL
              : this.userData.PERSON_ID;
          } else {
            this.editedItem.USERNAME = this.userData.USERNAME
              ? this.userData.USERNAME
              : null;
          }
          this.editedItem.DATE_OF_BIRTH = this.userData.DATE_OF_BIRTH
            ? this.userData.DATE_OF_BIRTH
            : null;
          this.editedItem.schoolName = this.userData.school
            ? this.userData.school.name
            : null;
          fb.db.collection(this._getPath()).doc().set(this.editedItem);
        } else {
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        if (!this.editedItem.phone) {
          this.$swal.fire("Утасны дугаараа оруулна уу!");
        } else this.$swal.fire("Асуудлаа дэлгэрэнгүй тодорxой бичнэ үү!");
      }
    },
    _read() {
      var query = fb.db.collection(this._getPath());
      if (this.userData.role != "superadmin")
        query = query.where("createdBy", "==", this.userData.ref);
      else {
        if (this.selectedDateFilter != "All") {
          var milliseconds = new Date().getTime();
          if (this.selectedDateFilter == "Today") {
            query = query.where(
              "createdAt",
              ">=",
              new Date(milliseconds - 86400000)
            );
          } else if (this.selectedDateFilter == "Last Week") {
            query = query.where(
              "createdAt",
              ">=",
              new Date(milliseconds - 604800000)
            );
          } else if (this.selectedDateFilter == "Last Month") {
            query = query.where(
              "createdAt",
              ">=",
              new Date(milliseconds - 2678400000)
            );
          }
        }
      }
      query
        .orderBy("createdAt", "desc")
        // .limit(500)
        .onSnapshot((querySnapshot) => {
          this.items = [];
          this.schools = [];
          var counter = 0;
          var sCounter = 0;
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            counter++;
            item.index = counter;
            var found = this.schools.find((ss) => ss.name == item.schoolName);
            if (!found) {
              sCounter++;
              this.schools.push({ index: sCounter, name: item.schoolName });
            }
            this.items.push(item);
          });
          this.items.sort(
            (a, b) =>
              new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
          );
        });
    },
    _getPath() {
      return "_problems";
    },
  },
};
</script>
<style>
.issueTableBorder td {
  border: 1px solid #bbb !important;
  padding: 2px 4px !important;
}

.issueTableBorder th {
  border: 1px solid #bbb !important;
}

.issueTableBorder .borderCell:hover {
  background-color: red;
}

.cancel-btn-class {
  background-color: grey !important;
  color: black;
}
</style>
